
import { Header, Socials, Background} from './components';
import './App.css';

import React from 'react';

function App() {
  return (
    <div className="App">
      <Background/>
      {/* <Header /> */}
      <Socials />
    </div>
  );
}

export default App;
