import { useRef, useEffect, useState } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { WaveMaterial } from './WaveMaterial'
import './background.css';

function ShaderPlane() {
  const ref = useRef()
  const { width, height } = useThree((state) => state.viewport)
  // const [show, setShow] = useState(false)

  useFrame((state, delta) => (ref.current.time += 0.01))

  return (
    <mesh scale={[width, height, 1]}>
      <planeGeometry />
      {/* <waveMaterial ref={ref} key={WaveMaterial.key} toneMapped={true} colorStart={'#5C0000'} colorEnd={'black'} />  */}
      {/* <waveMaterial ref={ref} key={WaveMaterial.key} toneMapped={true} colorStart={'#9A491B'} colorEnd={'#015c86'} /> */}
      <waveMaterial ref={ref} key={WaveMaterial.key} toneMapped={true} colorStart={'#00a6ff'} colorEnd={'#015c86'} />
      {/* <waveMaterial ref={ref} key={WaveMaterial.key} toneMapped={true} colorStart={'#015c86'} colorEnd={'#00a6ff'} /> */}
      {/* <waveMaterial ref={ref} key={WaveMaterial.key} toneMapped={true} colorStart={'#021533'} colorEnd={'#064F6F'} />  */}
    </mesh>
  )
}

const Background = () => {
  return (
     // <div style={{ width: "100vw", height: "200vh" }}>
    <>
    
    <div className='backgroundA'>
    <Canvas>
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <ShaderPlane />
    </Canvas>
    </div>
    </>
  )
}

export default Background;