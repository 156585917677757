import React from 'react';
import './socials.css';

import { SocialIcon } from 'react-social-icons';

const Socials = () => {

  return (
    <div className="socials">
      <SocialIcon style={{ height: 79, width: 79, margin: 10}} fgColor='white' url="https://www.linkedin.com/in/louis-genieys/" />
      <SocialIcon style={{ height: 79, width: 79, margin: 10}} fgColor='white' url="https://github.com/louisgenie" />
      <SocialIcon style={{ height: 79, width: 79, margin: 10}} fgColor='white' url="https://soundcloud.com/lgenieys" />
    </div>
  );
};

export default Socials;
